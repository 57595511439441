#posiciona {
  position:absolute ; /* posição absoluta ao elemento pai, neste caso o BODY */
  /* Posiciona no meio, tanto em relação a esquerda como ao topo */
  left: 5%; 
  top: 0%;
  width: 90%; /* Largura da DIV */
  height: 500px;
  min-height: 500px;
  align-items: center;
  /* A margem a esquerda deve ser menos a metade da largura */
  /* A margem ao topo deve ser menos a metade da altura */
  /* Fazendo isso, centralizará a DIV */
  margin-left: 0px;
  margin-top: 0px;
  text-align: center; /* Centraliza o texto */
  z-index: 1000; /* Faz com que fique sobre todos os elementos da página */
  color: black;
  padding: 5px;
  text-align: center;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: calc(2px + 2vmin);
}

.App {
  background-image: url("../imagens/fundosup.png");
  background-attachment: scroll;
  background-size: auto;
  background-color: black;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
}

.Topo {
  background-attachment: scroll;
  background-size: auto;
  height: 800px;
  min-height: 500px;
  height:auto;
  width:1440px;
  max-width:100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: line;
}
.Menu {
	height:auto;
  background-color: #DADADA;
  display: flex;
  flex-wrap: wrap;
  flex-direction: line;
  text-align: start;
  align-items: center;
  align-content: space-around;
  white-space: inherit;
  margin-top: 20px;
  padding: 10px;
  width:50%;
  max-width:50%;
  min-width: 50%;
  min-height: 50px;
  font-size: calc(7px + 2vmin);
  font-weight: bold;
  color: #A5551B;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.Menufundo {
  background-color: #F97C21;
  background-attachment: scroll;
  background-size: auto;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
}
.Toposapp {
  background-color: #DADADA;
  color: black;
  padding: 10px;
  margin-top: 20px;
  text-align: left;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: calc(3px + 2vmin);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Inicio {
  background-color: #F97C21;
  color: #fff;
	max-width:100%;
  min-height: 50%;
  display: flex;
  flex-direction: line;
  justify-content: center;
  align-items: center;
  text-align: justify;
  min-height: 300px;
  font-size: calc(9px + 2vmin);
  padding: 50px;
}
.InicioTitulo {
  background-color: #F97C21;
  color: #fff;
  max-width: 100%;
  min-width: 50%;
  min-height: 20px;
  font-size: calc(10px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  padding: 15px;
}
.Igr {
  background-color: #F97C21;
  color: #fff;
  width: 100%;
	max-width:100%;
  min-width: 50%;
  height: 100%;
  max-height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
  padding: 50px;
}
.IgrTitulo {
  background-color: #F97C21;
  color: #FFF;
  max-width: 100%;
  min-width: 50%;
  min-height: 20px;
  font-size: calc(12px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  padding: 15px;
}
.Listaigr {
	height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: left;
  align-items: center;
  align-content: space-around;
  white-space: inherit;
  margin: 1px;
  padding: 5px;
  width:100%;
  max-width:100%;
  min-width: 50%;
  min-height: 10px;
  font-size: calc(4px + 2vmin);
}
.Barra {
  background-color: #F97C21;
  color: #fff;
  width: 100%;
	max-width:100%;
  min-width: 50%;
  height: 15px;
  max-height: 15%;
  min-height: 15px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Barrinha {
  background-color: #A5551B;
  color: #fff;
  width: 50%;
	max-width:50%;
  min-width: 30%;
  height: 10px;
  max-height: 10%;
  min-height: 10px;
  padding: 5px;
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.Educa {
  background-color: #C4C4C4;
  width: 100%;
	max-width:100%;
  min-width: 50%;
  height: 100%;
  max-height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
  padding: 50px;
}
.EducaTitulo {
  background-color: #C4C4C4;
  color: black;
  max-width: 100%;
  min-width: 50%;
  min-height: 20px;
  font-size: calc(12px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  padding: 15px;
}

.Youtube {
  background-color: #6D88C0;
  width: 100%;
	max-width:100%;
  min-width: 50%;
  height: 100%;
  max-height: 100%;
  min-height: 260px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
}
.YoutubeTitulo {
  background-color: #6D88C0;
  color: black;
  max-width: 100%;
  min-width: 50%;
  min-height: 20px;
  font-size: calc(12px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  padding: 15px;
}
.MioloEspaco {
  width: 8%;
	max-width:8%;
  min-width: 5%;
  height: 100%;
  min-height: 200px;
}
.MioloYoutubeTexto {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 34%;
	max-width:34%;
  min-width: 17%;
  height: 100%;
  min-height: 200px;
}
.MioloYoutubeVideo {
  width: 54%;
	max-width:54%;
  min-width: 27%;
  height: 100%;
  min-height: 200px;
}
.MioloClaro {
  width: 85%;
	max-width:85%;
  min-width: 45%;
  height: 100%;
  max-height: 100%;
  min-height: 200px;
  border-radius: 20px;  
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
}
.MioloEscuro {
  width: 85%;
	max-width:85%;
  min-width: 45%;
  height: 100%;
  max-height: 100%;
  min-height: 200px;
  border-radius: 20px;  
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
}
.MioloVermelho {
  width: 85%;
	max-width:85%;
  min-width: 45%;
  height: 100%;
  max-height: 100%;
  min-height: 200px;
  border-radius: 20px;  
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
}
.MioloEsquerda {
	max-width:30%;
  width: 30%;
  height: 100%;
  min-height: 150px;
  border-radius: 40px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  text-align: justify;
  font-size: calc(10px + 2vmin);
  padding: 15px;
}
.MioloDireita {
  max-width:70%;
  width: 70%;
  height: 100%;
  min-height: 300px;
  border-radius: 40px;
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
}
.Drive {
  max-width: 100%;
  width: 100%;
  height: 100%;
  min-height: 200px;
  border-radius: 40px;
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  text-align: justify;
  font-size: calc(10px + 2vmin);
}
.Projetos {
  background-color: white;
	width:100%;
	height:auto;
  display: flex;
  align-content: space-between;
  text-align: center;
  flex-direction: row;
  color: black;
  text-decoration-color: black;
  font-size: calc(8px + 2vmin);
}
.ProjetoInterno {
  background-color: #FECD2A;
  height: 300px;
  width: 300px;
  max-width: 300px;
  max-width: 300px;
  min-width: 200px;
  min-height: 200px;
  border-radius: 1.5em;
	height:auto;
  display: inline-block;
  flex-direction: column;
  color: black;
  text-decoration-color: black;
  margin: 1em;
  padding: 1em;
  font-size: calc(5px + 2vmin);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Cidades {
  background-color: #00AFF0;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(8px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
}
.CidadesTitulo {
  background-color: #00AFF0;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  min-width: 50%;
  min-height: 20px;
  font-size: calc(8px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
}
.Cidadescada {
  background-color: #fff;
  height: 240px;
  width: 200px;
  max-width: 200px;
  min-width: 100px;
  max-height: 240px;
  min-height: 120px;
  margin: 15px;
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  font-size: calc(5.5px + 2vmin);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}
.Imprensacada {
  background-color: #fff;
  height: 150px;
  width: 150px;
  max-width: 150px;
  min-width: 80px;
  max-height: 150px;
  min-height: 80px;
  margin: 15px;
  border-radius: 15px;
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  font-size: calc(2px + 2vmin);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}
.Rotasimg {
  height: 100px;
  width: 100px;
  max-width: 100px;
  min-width: 50px;
  max-height: 100px;
  min-height: 50px;
  border-radius: 5px;
  margin: 3px;
  object-fit: cover;
}
.Mapainterativoimg {
  height: 200px;
  width: 200px;
  max-width: 200px;
  min-width: 100px;
  max-height: 200px;
  min-height: 100px;
  border-radius: 5px;
  margin: 3px;
  object-fit: cover;
}
.Imprensaimg {
  height: 90px;
  width: 150px;
  max-width: 150px;
  min-width: 60px;
  max-height: 90px;
  min-height: 60px;
  border-radius: 15px;
  object-fit: cover;
}

.ParceirosTitulo {
  background-color: #A5551B;
  color: black;
  max-width: 100%;
  min-width: 50%;
  min-height: 20px;
  font-size: calc(12px + 2vmin);
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  padding: 15px;
}
.Parceiros {
  background-color: #A5551B;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: column;
  max-width:100%;
  min-width: 50%;
  min-height: 200px;
  font-size: calc(8px + 2vmin);
  text-align: center;
}
.Parceiroscada {
  height: 200px;
  width: 200px;
  max-width: 200px;
  min-width: 100px;
  max-height: 200px;
  min-height: 100px;
  font-size: calc(8px + 2vmin);
  text-align: center;
}
.Rodape {
  background-color: #DADADA;
  color: #000;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 150px;
  font-size: calc(5px + 1vmin);
  align-items: center;
}
.Barrafinal  {
  background-color: #A5551B;
  color: #fff;
	max-width:100%;
  min-width: 50%;
	height:auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: calc(5px + 1vmin);
}

.Espaco {
  background-color: white;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 10px;
  font-size: calc(8px + 2vmin);
}

.App-patrimoniominas {
  width: 150px;
	max-width: 150px;
  min-width: 20px;
  height: auto;
  text-align: right;
  vertical-align: text-top;
}
.App-logo {
	max-width:100%;
  min-width: 30%;
  height:auto;
  width: 635.6px;
}

.Img {
	max-width:100%;
  min-width: 30%;
  height:auto;
  width: 500px;
  margin: 10px;
}
.App-celular {
	max-width:100%;
  min-width: 10%;
  height:auto;
  width: 230px;
  margin: 10px;
}

.App-logotop {
	max-width: 200px;
  min-width: 50px;
  max-height: auto;
  min-height: auto;
}

.App-logoinferior {
  width: 250px;
	max-width: 250px;
  min-width: 20px;
  height: auto;
  margin: 20px;
}
.App-parceiro {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 200px;
  margin: 50px;
}
.App-header {
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.App-divesquerda {
  width: 15%;
	max-width:15%;
  min-width: 8%;
  height: auto;
  flex-direction: column;
  align-items: left;
  vertical-align: center;
  text-align: left;

}
.App-divdireita {
  width: 80%;
  max-width:80%;
  min-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  vertical-align: top;
  text-align: right;
}
.Textocircuito-divesquerda {
  width: 50%;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 20px;
  margin: 10px;
  font-size: calc(10px + 2vmin);
  padding: 20px;
}
.App-divdireitat {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(12px + 3vmin);
  color: black;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 20px;
  margin: 30px;
}
.App-divesquerdab {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
}
.App-divdireitab {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.App-content {
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  transform: 'translate(-50%, -50%)';
}

a:link, a:visited, a:active {
	text-decoration:none;
  color: #A5551B;
	}
a:hover {
	text-decoration:none; 
	color:rgb(0, 0, 0); 
  text-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
	}

.action{
    padding: 0px;
    margin: 10px;
    align-items: center;
    display: inline-block;
    border-radius: 4px;;
  }
  .react-slideshow-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .react-slideshow-container .nav {
    z-index: 10;
  }
  
  .react-slideshow-container .default-nav {
    height: 30px;
    background: rgba(255, 255, 255, 0.6);
    width: 30px;
    border: none;
    text-align: center;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  

  
  .react-slideshow-container .default-nav:hover,
  .react-slideshow-container .default-nav:focus {
    background: #fff;
    color: #666;
    outline: none;
  }
  
  .react-slideshow-container .default-nav.disabled:hover {
    cursor: not-allowed;
  }
  
  .react-slideshow-container .default-nav:first-of-type {
    margin-right: -30px;
    border-right: none;
    border-top: none;
  }
  
  
  .react-slideshow-container .default-nav:last-of-type {
    margin-left: -30px;
  }
  
  
  .react-slideshow-container + ul.indicators {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 20px;
  }
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .react-slideshow-container + ul.indicators li {
    display: inline-block;
    position: relative;
    width: 7px;
    height: 7px;
    padding: 5px;
    margin: 0;
  }
  
  .react-slideshow-container + ul.indicators .each-slideshow-indicator {
    border: none;
    opacity: 0.25;
    cursor: pointer;
    background: transparent;
    color: transparent;
  }
  
  .react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    content: '';
    background: #000;
    text-align: center;
  }
  
  .react-slideshow-container + ul.indicators .each-slideshow-indicator:hover,
  .react-slideshow-container + ul.indicators .each-slideshow-indicator.active,
  .react-slideshow-container + ul.indicators .each-slideshow-indicator:focus {
    opacity: 0.75;
    outline: none;
  }
  
  .react-slideshow-fade-wrapper {
    width: 100%;
    overflow: hidden;
  }
  
  .react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  
  .react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap > div {
    position: relative;
    opacity: 0;
  }
  
  .react-slideshow-wrapper.slide {
    width: 100%;
    overflow: hidden;
  }
  
  .react-slideshow-wrapper .images-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  
  .react-slideshow-zoom-wrapper {
    width: 100%;
    overflow: hidden;

  }
  
  .react-slideshow-zoom-wrapper .zoom-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    overflow: hidden;
    background-color: '#AACE45';
  }
  
  .react-slideshow-zoom-wrapper .zoom-wrapper > div {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }