
.modal{
  position: fixed;
  top: 0;
  bottom:0;
  right: 0;
  left: 0;
  background: rgba(0,0,0, 0.6);
  z-index: 99;
}

.modal.container{
  position: fixed;
  width: 100%;
  max-width: 100%;
  top: 5px ;
  left:0;
  right: 0;
  margin: 0 auto;
  padding: 4em 2rem;
  background-color: #FFF;
  box-shadow: 0 0 20px rgba(0,0,0, 0.8);
}

.close{
  background-color: #F65835;
  border:0;
  color: #FFF;
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  border-radius: 5px;
}

.close svg{
  margin-right: 5px;
}

.modal h2{
  margin-bottom: 1.2em;
  font-size: 1em;
}

.modal span{
  font-weight: bold;
  font-size: 1em;
  color: #121212;
}

.modal span i{
  font-weight: 400;
  margin-right: 1em;
  padding: 2px 8px;
  border-radius: 3px;
}

.row{
  margin-bottom: 1em;
  width: 100%;
  max-width: 100%;
}

.modal p{
  padding-top: 0.5em;
  white-space: pre-wrap;
}